import React, { useEffect, useState } from 'react';
import { CategoryApis } from '../../apis/CategoryApis';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { ProductApis } from '../../apis/productApis';
import { FaArrowLeft } from 'react-icons/fa';

const CreateCardDeals = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [productIds, setProductIds] = useState([]); // Selected product IDs
  const [products, setProducts] = useState([]); // Products to display in the dropdown
  const [dealName, setDealName] = useState('');
  const [percentDiscount, setPercentDiscount] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(1); // Current page for pagination
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  // Fetch products with pagination
  const fetchProducts = (page) => {
    setLoading(true);
    ProductApis.getAllProducts(page, { search: '', name: '', categories: '' })
      .then((response) => {
        if (response?.data?.data) {
          setProducts((prev) => [...prev, ...response.data.data.data]);
          setHasMore(!!response.data.data.next_page_url);
        } else {
          console.error('Unexpected data format:', response?.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      })
      .finally(() => setLoading(false));
  };

  // Load initial products and on page change
  useEffect(() => {
    fetchProducts(page);
  }, [page]);

  // Handle product selection
  const toggleProductSelection = (productId) => {
    setProductIds((prevIds) =>
      prevIds.includes(productId) ? prevIds.filter((id) => id !== productId) : [...prevIds, productId]
    );
  };

  // Handle form submission
  const createProduct = (e) => {
    e.preventDefault();
    setLoader(true);

    const formData = {
      name: dealName,
      percent_discount: percentDiscount,
      start_date: startDate,
      end_date: endDate,
      product_ids: productIds, // Array of selected product IDs
    };

    CategoryApis.createDeals(formData)
      .then((response) => {
        if (response?.data) {
          toast.success(response?.data?.message);
          navigate('/admin/deals');
        } else {
          toast.error(response?.response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => setLoader(false));
  };
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <div className='mt-4'>
        {/* Back button */}
        <button type="button" onClick={handleBackClick} className="flex items-center text-gray-600 mb-4">
        <FaArrowLeft className="mr-2" />
        <span>Back</span>
      </button>
      <form onSubmit={createProduct} className="pb-32 sm:px-3">
        <div className="mb-10 lg:px-32 lg:w-5/5">
          <div className="grid md:grid-cols-2 gap-2">
            <div>
              <label className="block mb-2 mt-6 text-sm text-gray-900">Deal Name</label>
              <input
                required
                type="text"
                value={dealName}
                onChange={(e) => setDealName(e.target.value)}
                className="bg-[#F4FBFF] border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="Deals Name"
              />
            </div>

            <div>
              <label className="block mb-2 mt-6 text-sm text-gray-900">Percent discount</label>
              <input
                required
                type="number"
                value={percentDiscount}
                onChange={(e) => setPercentDiscount(e.target.value)}
                className="bg-[#F4FBFF] border border-gray-300 text-sm rounded-lg w-full p-2.5"
                placeholder="Eg.10,20..."
              />
            </div>
          </div>

          {/* Date Inputs */}
          <div className="grid md:grid-cols-2 gap-2">
            <div>
              <label className="block mb-2 mt-6 text-sm text-gray-900">Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="bg-[#F4FBFF] border border-gray-300 text-sm rounded-lg w-full p-2.5"
              />
            </div>

            <div>
              <label className="block mb-2 mt-6 text-sm text-gray-900">End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="bg-[#F4FBFF] border border-gray-300 text-sm rounded-lg w-full p-2.5"
              />
            </div>
          </div>

          {/* Product Selection with Pagination */}
          <div>
            <label className="block mb-2 mt-6 text-sm text-gray-900">Select Products</label>
            <div className="border rounded-lg p-3 max-h-60 overflow-y-auto bg-[#F4FBFF]">
              {products.map((product) => (
                <div key={product.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    checked={productIds.includes(product.id)}
                    onChange={() => toggleProductSelection(product.id)}
                    className="mr-2"
                  />
                  <span>{product.product_name}</span>
                </div>
              ))}
              {loading && <p>Loading...</p>}
            </div>
            {hasMore && (
              <button
                type="button"
                onClick={() => setPage((prevPage) => prevPage + 1)}
                className="mt-2 text-blue-500"
              >
                Load More
              </button>
            )}
          </div>

          {/* Submit Button */}
          <div className="flex justify-center gap-2 mt-5">
            <button
              type="submit"
              className="text-white h-10 bg-[#0071BC] hover:bg-[#103f5e] font-medium rounded-lg px-10 mt-10 py-2.5 text-center"
            >
              Create
            </button>
            <Oval height={40} width={40} color="#0071BC" visible={loader} />
          </div>
        </div>
      </form>

      <ToastContainer position="top-right" autoClose={2000} hideProgressBar pauseOnHover />
    </div>
  );
};

export default CreateCardDeals;
