import React, { useEffect } from "react";
import Navbar from "../Navbars/Navbar";
import FooterAdmin from "../Footers/FooterAdmin";
import Footer from "../Footers/Footer";
import { useLocation, useNavigate } from "react-router-dom";

const PayWithTransfer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location:any = useLocation();

  const  cartData  = location.state;
  // console.log(cartData)

  const handleClick = () => {
    navigate("/verify-payment-details", { state: cartData });
  };
  return (
    <>
      <Navbar />
      <div className="flex justify-center py-10">
        <div className="flex flex-col gap-5 bg-gray-100 p-4 rounded">
          <p>Here are the payment details:</p>
          <p>
            <b>Account Number:</b> 4831280948
          </p>
          <p>
            <b>Bank:</b> Moniepoint MFB
          </p>
          <p>
            <b>Account Name:</b> Mmart Plus
          </p>
          <p>
            Message{" "}
            <a
              href="https://wa.me/2348148268019?text=Hello%20I%20would%20like%20to%20confirm%20my%20transfer%20"
              rel="noopener noreferrer"
              target="_blank"
              className="text-blue-700  underline"
            >
              +2348148268019
            </a>{" "}
            for confirmation. Pickup is available at our store during working
            hours.
          </p>
        </div>
      </div>

      <div className="flex gap-2 justify-center">
        <button
          onClick={() => navigate("/")}
          className=" text-white bg-[#027DCB] font-medium rounded-[5px] text-sm px-5 py-3 mr-2 mt-2 mb-2"
        >
          Back to home
        </button>
        <button
      onClick={handleClick}
          className=" text-white bg-[#027DCB] font-medium rounded-[5px] text-sm px-5 py-3 mr-2 mt-2 mb-2"
        >
         Get Receipt
        </button>
      </div>
      <Footer />
    </>
  );
};

export default PayWithTransfer;
