import React, { useRef, useState } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { StoreApis } from "../../apis/storeApis";
import {
  useLoadScript,
  StandaloneSearchBox,
  LoadScriptProps,
} from "@react-google-maps/api";

const libraries: LoadScriptProps["libraries"] = ["places"];

const CardEditStore = () => {
  const navigate = useNavigate();
  let [visible, setVisible] = React.useState(false);
  const params = useParams();

  const [storeName, setStoreName] = useState("");
  const [productId, setProductId] = useState("");
  const [addressName, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [loader, setLoader] = React.useState(false);
  let [userData, setUserData] = React.useState({
    name: "",
    address: "",
    other_data: {
      base_price: "",

    }

  });
  React.useEffect(() => {
    StoreApis.getSingleStore(params?.id).then((response) => {
      if (response?.data) {
        console.log(response.data)
        setUserData(response?.data);
        setStoreName(response?.data?.name);
        setAddress(response?.data?.address);
        setStatus(response?.data?.status);
        setProductId(response?.data?.id);
      }
    });
  }, [params?.id]);

  
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
  
    if (name in userData.other_data) {
      // Handle nested 'other_data' updates
      setUserData({
        ...userData,
        other_data: {
          ...userData.other_data,
          [name]: type === "number" ? parseFloat(value) || "" : value, // Convert if type is 'number'
        },
      });
    } else {
      // Handle top-level updates
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };
  
  const updateStore = React.useCallback(
    (e: any) => {
      e.preventDefault();
      setLoader(true);
      const data = {
        name: userData?.name,
        address: userData?.address,
        base_price: userData?.other_data?.base_price,
        // faq_category: userData?.faq_category,
        id: productId,
      };
      // console.log('FormData values:', {
      //   name: storeName,
      //   address: addressName,
      //   status: status,
      //   id: productId,
      // });

      StoreApis.updateStore(productId, data)
        .then((response) => {
          if (response?.data) {
            setLoader(false);
            toast.success("Store Updated Successfully");
            navigate("/admin/stores");
          } else {
            setLoader(false);
            toast.error("Something went wrong");
          }
        })
        .catch(function (error) {
          setLoader(false);
          console.error("Error response:", error.response);
          toast.error(error.response.data.message);
        });
    },
    [userData, productId]
  );

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const deleteLink = React.useCallback(
    (e: any) => {
      e.preventDefault();
      setLoader(true);
      StoreApis.deleteStore(productId)
        .then((response) => {
          if (response?.data) {
            // console.log(response.data)
            // setToggleDeleteModal(false)
            toast.success("Link Deleted Successfully");
            setLoader(false);
            navigate("/admin/stores");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.data);
          toast.error("Offfline");
        })
        .finally(() => {
          //toast.error("No Internet Connection");
        });
    },
    [productId]
  );

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA8uPmEdNIXnG7TXLMf2iECVp5wAmXczeY", // Replace with your actual API key
    libraries,
  });

  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  if (!isLoaded) return <div>Loading...</div>;
  if (loadError) return <div>Error loading maps</div>;

  const handleLoad = (searchBox: google.maps.places.SearchBox) => {
    searchBoxRef.current = searchBox;
  };

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
      const selectedAddress = places[0].formatted_address || "";
      setUserData((prevData) => ({ ...prevData, address: selectedAddress }));
      setSuggestions([]);
    }
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setUserData((prevData) => ({ ...prevData, address: input }));

    if (input.length > 2) {
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        { input },
        (predictions, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  return (
    <>
      <form onSubmit={updateStore} className="pb-32 sm:px-5">
        <div className="flex  justify-between items-center mt-4">
          {/* Back button */}
          <button
            type="button"
            onClick={handleBackClick}
            className="flex items-center text-gray-600 mb-4"
          >
            <FaArrowLeft className="mr-2" />
            <span>Back</span>
          </button>

          <div className="flex justify-end gap-2 mt-5">
            {/* <button
            type="button"
            onClick={toggleModal}
            className=" text-gray-900 bg-[#8ed2ff] hover:bg-[#167bbe] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
          >
            Add Market Link
          </button> */}
            {loader ? (
              <div className="flex justify-center ">
                <Oval
                  height={40}
                  width={40}
                  color="#0071BC"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loader}
                  ariaLabel="oval-loading"
                  secondaryColor="#96cff6"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              ""
            )}

            <button
              type="submit"
              className=" text-white bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
            >
              Update Store
            </button>
          </div>
        </div>
        <div
          className={
            "lg:grid lg:grid-cols-2 gap-2 mt-20 " +
            (loader ? "shadow animate-pulse " : "")
          }
        >
          <div className="mb-10">
            <div className=" lg:w-4/5">
              <label
                htmlFor="name"
                className="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600"
              >
                Store Name
              </label>
              <input
                type="text"
                name="name"
                value={userData?.name || ""}
                onChange={handleChange}
                id="name"
                className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Name of store here"
              />

              <StandaloneSearchBox
                onLoad={handleLoad}
                onPlacesChanged={handlePlacesChanged}
              >
                <div className="relative mb-6">
                  <label className="block mb-2 text-sm font-semibold text-[#414143]">
                    Address
                  </label>

                  <textarea
                    className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    placeholder="Enter Store Address"
                    name="address"
                    required
                    rows={3}
                    // value={addressName}
                    value={userData?.address || ""}
                    // onChange={handleChange}
                    onChange={(e: any) => handleInputChange(e)}
                  />
                </div>
              </StandaloneSearchBox>
              {suggestions.length > 0 && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.place_id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        setUserData((prevData) => ({
                          ...prevData,
                          address: suggestion.description,
                        }));
                        setSuggestions([]);
                      }}
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}


<div className=" lg:w-4/5">
              <label
                htmlFor="base_price"
                className="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600"
              >
               Base Price
              </label>
              <input
                type="number"
                value={userData?.other_data?.base_price || ""}
                onChange={handleChange}
                id="base_price"
                name="base_price"
                className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                // placeholder="Enter Base Price"
              />
            </div>
              {/* <label htmlFor="first_name" className="block mb-2 mt-4 text-sm  text-gray-900 dark:text-gray-600 ">Store Address</label>
              <textarea 
              id="address" 
              name="address"
              value={userData?.address || ''} 
              onChange={handleChange}
              rows={3} 
              className="block bg-[#F4FBFF] p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-gray-500 focus:border-gray-500"
               placeholder="Address" ></textarea> */}

              {/* <div className="grid md:grid-cols-2 gap-2">
                <div>
                  <label htmlFor="first_name" className="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Sizes</label>
                  <input type="text" defaultValue={count} onChange={(e) => setCount(e?.target?.value)} id="first_name" className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Lg,Md,Sm" />
                </div>

                <div>
                  <label htmlFor="first_name" className="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Number of Items </label>
                  <input type="number" defaultValue={noOfItems} onChange={(e) => setNoOfItems(e?.target?.value)} id="first_name" className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="1,2,3..." />
                </div>
              </div>
 */}
            </div>
            <button
              //  style={{backgroundColor:'#61A24F'}}
              className={
                "text-xs cursor-pointer block mt-5 rounded-md px-3 bg-[#FF0000]"
              }
              onClick={deleteLink}
            >
              <span className="flex py-2  cursor-pointer">
                <span className=" text-[15px]  font-normal text-white">
                  {loader ? (
                    <div className="flex justify-center ">
                      <Oval
                        height={20}
                        width={20}
                        color="#0071BC"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loader}
                        ariaLabel="oval-loading"
                        secondaryColor="#96cff6"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  ) : (
                    " Delete"
                  )}
                </span>
                {/* <span style={{ color: 'red' }}>  <SvgElement type={icontypesEnum.REDARROW} /> </span> */}
              </span>
            </button>
          </div>
        </div>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CardEditStore;
