
import React, { useState } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-awesome-modal';
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit } from "react-icons/fa";
import { AiOutlineWarning } from "react-icons/ai";
import EmojiPicker from 'emoji-picker-react';
import { Oval } from 'react-loader-spinner'
import InputColor from 'react-input-color';
import { CategoryApis } from "../../apis/CategoryApis";
import { FaArrowLeft } from "react-icons/fa";

// components



export default function CardCreateCategory() {
  const navigate = useNavigate();
  let [visible, setVisible] = React.useState(false);
  const [addLink, setAddLink] = React.useState([]);

  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('null');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [noOfItems, setNoOfItems] = useState('');
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');

  const [title, setTitle] = useState('');
  const [bio, SetBio] = useState('');


  const [img1, setImg1] = React.useState('No selected file');
  const [img12, setImg12] = React.useState('empty');
  function uploadImg1(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg12(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 1) {
        if (e.target.name == 'uploadImg1') {
          e.target.value = ''
          toast.warn('Image too large')
        }
      }
      if (size <= 1) {
        if (e.target.name == 'uploadImg1') {
          setImg1(e.target.files[0]);
        }
      }
    }
  };


  const [img2, setImg2] = React.useState('No selected file');
  const [img22, setImg22] = React.useState('empty');
  function uploadImg2(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg22(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 1) {
        if (e.target.name == 'uploadImg2') {
          e.target.value = ''
          toast.warn('Image too large')
        }
      }
      if (size <= 1) {
        if (e.target.name == 'uploadImg2') {
          setImg2(e.target.files[0]);
        }
      }
    }
  };

  const [img3, setImg3] = React.useState('No selected file');
  const [img32, setImg32] = React.useState('empty');
  function uploadImg3(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg32(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 1) {
        if (e.target.name == 'uploadImg3') {
          e.target.value = ''
          toast.warn('Image too large')
        }
      }
      if (size <= 1) {
        if (e.target.name == 'uploadImg3') {
          setImg3(e.target.files[0]);
        }
      }
    }
  };

  const [loader, setLoader] = React.useState(false);
  let [data, setdata] = React.useState(0);
  let [checkLink, setCheckLink] = React.useState('');

  const createProduct = React.useCallback(
    (e) => {
      e.preventDefault();
      if (img1 == 'No selected file') {
        toast.error("Upload Product Images");
      } else {

        setLoader(true)
        const formData = new FormData()
        formData.append('cat_name', productName)
        formData.append('cat_image', img1)
        CategoryApis.createCategory(formData).then(
          (response) => {
            if (response?.data) {
              // console.log(response?.data)
              // toggleModal()
              setLoader(false)
              toast.success(response?.data?.message);
              navigate('/admin/categories');
            } else {
              // toggleModal()
              toast.error(response?.response?.data?.message);
            }

            // toast.success(response?.data?.message);
          }
        ).catch(function (error) {
          // handle error
          // console.log(error.response);
          setLoader(false)
          toast.error(error.response.data.message);
        }).finally(function () {
          setLoader(false)
        })
      }
    },
    [productName, img1]
  );

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <>
      <form onSubmit={createProduct} className="pb-32 sm:px-3">
      <div className="flex mt-4 justify-between items-center">
                      {/* Back button */}
   <button type="button" onClick={handleBackClick} className="flex items-center text-gray-600 mb-4">
        <FaArrowLeft className="mr-2" />
        <span>Back</span>
      </button>
        <div className="md:flex md:justify-end">
          {/* <div className="bg-blue-100 md:w-2/5 rounded-lg m-1 p-2">
            <span className=" bg-blue-100  rounded-lg  text-gray-500 text-[12px]"><span className=" text-red-500 bg-red-200 p-1 px-3 rounded-full text-[15px]">!</span><br />  ATTENTION : Please kindly note that the market link is the link where your customers will see the list of your products</span>
          </div> */}


          <div className="flex justify-end gap-2 mt-5">
           

            <button
              type="submit"
              className=" text-white h-10 bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
            >
              Add Category
            </button>

            <Oval
              height={40}
              width={40}
              color="#0071BC"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loader}
              ariaLabel='oval-loading'
              secondaryColor="#96cff6"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />

          </div>

        </div>
</div>

        <div className={"lg:grid lg:grid-cols-2 gap-2 mt-10 " + (loader ? 'shadow animate-pulse ' : '')}>
          <div className="mb-10">
            <div className=" lg:w-4/5">
              <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Category Name</label>
              <input required type="text" defaultValue={title} onChange={(e) => setProductName(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Enter Category Name" />

            </div>
          </div>




          {/* second Div */}
          <div className="grid grid-cols-2 gap-2">
            <div className="">
              <label className="flex flex-col items-center justify-center w-full  rounded-[5px] cursor-pointer ">
                <div className="flex flex-col items-center justify-center  ">
                  {img12 == 'empty' ? <img src="/images/img1.png" style={{ minHeight: '200px', maxHeight: "200px" }} /> : <img src={img12} style={{ minHeight: '200px', maxHeight: "200px" }} />}
                </div>
                <input id="dropzone-file" onChange={uploadImg1} accept="image/x-png,image/gif,image/jpeg" name='uploadImg1' type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
              </label>
            </div>

           

          </div>

        </div>


      </form>


      {/* CREATE MARKET LINK */}

   

   



      {/* DELETE MARKET LINK */}

    

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}
