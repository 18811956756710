import React, { useState, useRef, useEffect } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-awesome-modal";
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import { PaymentApis } from "../../apis/paymentApis";
import Navbar from "../Navbars/Navbar";
import { CartApis } from "../../apis/cartApis";
import dayjs from "dayjs";
import {
  useLoadScript,
  StandaloneSearchBox,
  LoadScriptProps,
} from "@react-google-maps/api";
import { StoreApis } from "../../apis/storeApis";
import { FaArrowDown, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { setCartData } from "../../store/stateSlice";
// components

const libraries = ["places"];

export default function CardCartPage() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const userLoginData = useSelector((state) => state.data.login.value);
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [totalItem, setTotalItem] = React.useState(0);
  const [address, setAddress] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [visible2, setVisible2] = React.useState(false);
  const [walletDetails, setWalletDetails] = React.useState([]);
  const [paid, setPaid] = React.useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState("");
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [selectedStoreName, setSelectedStoreName] = useState(null); // For store name

  // State for storing the selected store's id
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const togglePayTransfer = () => {
    setIsVisible((prev) => !prev);
  };

  const toggleDetails = () => setVisibleDetails((prev) => !prev);
  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }

    AdminApis.getWalletInfo().then((response) => {
      if (response?.data) {
        setWalletDetails(response?.data?.data);
      }
    });

    AdminApis.getProfile().then((response) => {
      if (response?.data) {
        setAddress(response?.data?.data?.address);
      }
    });

    CartApis.getAllcart().then((response) => {
      if (response?.data) {
        setData(response?.data?.cart);
        let tot = 0;
        let totItem = 0;
        response?.data?.cart?.map((data) => {
          tot = tot += data?.quantity * data?.price;
          totItem = totItem += 1;
        });
        setTotal(tot);
        setTotalItem(totItem);
      }
    });
  }, [paid]);

  let [visible, setVisible] = React.useState(false);
  let [value, setvalue] = React.useState("");

  function togglePaymentModal(value2) {
    setvalue(value2);
    setVisible(true);
  }

  let [location, setLocation] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(true);
    StoreApis.getStore()
      .then((response) => {
        if (response?.data) {
          // Filter out stores with status 'deleted'
          const activeStores = response?.data?.stores.filter(
            (store) => store?.status !== "deleted"
          );
          // Set the filtered stores to the state
          setStores(activeStores);
          setLoader(false);
        } else {
          // Handle case where no data is returned
        }
      })
      .catch(function (error) {
        console.error(error); // Handle any errors here
      });
  }, []);
  // Function to handle the selection of store

  const handleStoreSelection = (e, store) => {
    const selectedStoreAddress = e.target.value;
    setSelectedStore(selectedStoreAddress);
    setSelectedDeliveryMethod(""); // Reset delivery method when store changes
    setSelectedPaymentMethod(""); // Reset payment method when store changes
    getLocation(store.id, address); // Call getLocation with both addresses if needed
    setSelectedStoreName(store.name); // Update the store name state
    setSelectedStoreId(store.id); // Update the store id state
  };

  const getLocation = React.useCallback(
    (storeId, destinationAddress) => {
      if (!storeId || !destinationAddress) return; // Ensure both addresses are valid
      let data = {
        // origin: selectedStoreAddress,
        destination: destinationAddress,
        store_id: storeId,
      };

      setLoader(true);
      CartApis.getLocation(data)
        .then((response) => {
          if (response?.data) {
            setLocation(response?.data);
            // setVisible2(true);
            setLoader(false);
          }
        })
        .catch(function (error) {
          // Handle error
          console.log(error.response.data);
          toast.error("Offline");
        });
    },
    [selectedStore, selectedStoreId, stores]
  );
  const checkout = React.useCallback(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }

    let data = {
      amount: total,
      shippingFee: selectedDeliveryMethod === "shipping" ? location : 0,
      recieverAddress: address,
      deliveryType:
        selectedDeliveryMethod === "shipping" ? "shipping" : "pickUp",
    };

    setLoader(true);
    PaymentApis.payForProduct(data)
      .then((response) => {
        if (response?.data?.success) {
          window.location.replace(response?.data?.data?.link);
          setLoader(false);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response.data);
        toast.error("An error occured");
        setLoader(false);
      })
      .finally(() => {
        // window.location.reload();
        //toast.error("No Internet Connection");
        setLoader(false);
      });
  }, [total, address, location, selectedDeliveryMethod]);

  const PayAtStore = React.useCallback(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }

    let data = {
      amount: total,
      recieverAddress: address,
      shippingFee: 0,
      deliveryType:
        selectedDeliveryMethod === "shipping" ? "shipping" : "pickUp",
      // recieverAddress: address
      //   ? address
      //   : "Shalom Rd, Amuwo Odofin, 102102, Lagos",
    };
    setLoader(true);
    PaymentApis.payAtStore(data)
      .then((response) => {
        if (response?.data?.success) {
          setVisible2(false);
          setPaid(!paid);
          setLoader(false);
          // dispatch(setCartData(response?.data?.transaction));
          navigate("/verify-payment-details", { state: response?.data?.data });

          toast.success("Checkout Successful");
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response.data);
        toast.error("Offfline");
      })
      .finally(() => {
        // window.location.reload();
        //toast.error("No Internet Connection");
      });
  }, [total, address, paid, visible2, location, selectedDeliveryMethod]);

  const PayWithTransfer = React.useCallback(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }

    let data = {
      amount: total,
      recieverAddress: address,
      shippingFee: selectedDeliveryMethod === "shipping" ? location : 0,
      deliveryType:
        selectedDeliveryMethod === "shipping" ? "shipping" : "pickUp",

      // recieverAddress: address
      //   ? address
      //   : "Shalom Rd, Amuwo Odofin, 102102, Lagos",
    };
// console.log(data)
    PaymentApis.payAtStore(data)
      .then((response) => {
        if (response?.data?.success) {
          setLoader(true);
          setPaid(!paid);
          navigate("/view-cart/pay-with-transfer", {
            state: response?.data?.data,
          });
          setLoader(false);

          toast.success("transaction initiated Successful");
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response.data);
        toast.error("Offfline");
      })
      .finally(() => {
        // window.location.reload();
        //toast.error("No Internet Connection");
      });
  }, [total, address, paid, visible2, location, selectedDeliveryMethod]);

  const payFromWallet = React.useCallback(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }

    let data = {
      amount: total + location,
      recieverAddress: address,
      // recieverAddress: address
      //   ? address
      //   : "Shalom Rd, Amuwo Odofin, 102102, Lagos",
    };

    if (walletDetails?.total_amount >= total + location) {
      setLoader(true);
      PaymentApis.payFromWallet(data)
        .then((response) => {
          if (response?.data?.success) {
            setVisible2(false);
            setPaid(!paid);
            setLoader(false);

            toast.success("Checkout Successful");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.data);
          toast.error("Offfline");
        })
        .finally(() => {
          // window.location.reload();
          //toast.error("No Internet Connection");
        });
    } else {
      setVisible2(false);
      setPaid(!paid);
      toast.error("Insufficient Wallet Balance");
    }
  }, [total, address, visible2, paid, location]);

  const addCartCount = React.useCallback(
    (productInfo) => {
      if (!userLoginData?.id) {
        navigate("/sign-in");
      }
      let data = {
        product_id: productInfo?.product_id,
        product_price: productInfo?.price, // Ensure price is a number
        // price: productInfo?.price, // Ensure price is a number
        quantity: Number(productInfo?.quantity) + 1,
        product_name: productInfo?.product_name,
        product_img: productInfo?.product_img,
        user_id: userLoginData?.user_id,
      };

      CartApis.createCart(data)
        .then((response) => {
          if (response?.data?.status) {
            CartApis.getAllcart().then((response) => {
              if (response?.data) {
                setData(response?.data?.cart);
                let tot = 0;
                let totItem = 0;
                response?.data?.cart?.map((data) => {
                  tot = tot += data?.quantity * data?.price;
                  totItem = totItem += 1;
                });
                setTotal(tot);
                setTotalItem(totItem);
              }
            });
            window.location.reload();
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.data);
          toast.error("Offfline");
        })
        .finally(() => {});
    },
    [data, userLoginData, total, totalItem]
  );

  const reduceCartCount = React.useCallback(
    (productInfo) => {
      if (!userLoginData?.id) {
        navigate("/sign-in");
      }
      let data = {
        product_id: productInfo?.product_id,
        product_price: productInfo?.price,
        quantity:
          Number(productInfo?.quantity) <= 1
            ? 1
            : Number(productInfo?.quantity) - 1,
        product_name: productInfo?.product_name,
        product_img: productInfo?.product_img,
        user_id: userLoginData?.user_id,
      };
      CartApis.createCart(data)
        .then((response) => {
          if (response?.data?.status) {
            CartApis.getAllcart().then((response) => {
              if (response?.data) {
                setData(response?.data?.cart);
                let tot = 0;
                let totItem = 0;
                response?.data?.cart?.map((data) => {
                  tot = tot += data?.quantity * data?.price;
                  totItem = totItem += 1;
                });
                setTotal(tot);
                setTotalItem(totItem);
                window.location.reload();
              }
            });
            window.location.reload();
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.data);
          toast.error("Offfline");
        })
        .finally(() => {});
    },
    [data, userLoginData, total, totalItem]
  );

  const deleteItem = React.useCallback(
    (productInfo) => {
      if (!userLoginData?.id) {
        navigate("/sign-in");
      }

      CartApis.deleteCart(productInfo?.id)
        .then((response) => {
          if (response?.data?.success) {
            CartApis.getAllcart().then((response) => {
              if (response?.data) {
                setData(response?.data?.cart);
                let tot = 0;
                let totItem = 0;
                response?.data?.cart?.map((data) => {
                  tot = tot += data?.quantity * data?.price;
                  totItem = totItem += 1;
                });
                setTotal(tot);
                setTotalItem(totItem);
              }
            });

            toast.success("deleted Successfully");
            window.location.reload();
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error.response.data);
          toast.error("Offfline");
        })
        .finally(() => {});
    },
    [data, userLoginData, total, totalItem]
  );

  const handleProceed = () => {
    switch (selectedPaymentMethod) {
      case "checkout":
        checkout();
        break;
      case "payAtStore":
        PayAtStore();
        break;
      case "payWithTransfer":
        PayWithTransfer();
        break;
      case "payFromWallet":
        payFromWallet();
        break;
      default:
        break;
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA8uPmEdNIXnG7TXLMf2iECVp5wAmXczeY", // Replace with your actual API key
    libraries,
  });

  const [suggestions, setSuggestions] = useState([]);
  const searchBoxRef = useRef(null);

  if (!isLoaded)
    return (
      <div>
        <div className="md:flex justify-between gap-10">
          <div className=" md:basis-8/12 mt-10">
            <div className=" border-[2px] rounded-lg px-4 py-3 border-[#E3E4E5] ">
              <div className="flex justify-center items-center h-30 bg-gray-300 rounded dark:bg-gray-700"></div>

              <hr className="my-4" />

              <div className="flex justify-center items-center h-30 bg-gray-300 rounded dark:bg-gray-700"></div>
            </div>
          </div>

          <div className=" md:basis-4/12 mt-10 mb-5">
            <div className=" border-[2px] rounded-lg px-4 py-3 border-[#E3E4E5]">
              <div className="flex flex-col">
                <div className="flex justify-between my-3">
                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>

                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-24"></div>
                </div>

                <div className="flex items-center justify-between my-3">
                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>

                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
                </div>

                <div className="flex justify-between my-3">
                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>

                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
                </div>
                <div className="relative w-full lg:w-12/12">
                  <div className="flex justify-center items-center h-30 bg-gray-300 rounded dark:bg-gray-700"></div>
                </div>

                <hr className="my-3" />

                <div>
                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>

                  <form className="flex flex-col pb-3 gap-4">
                    <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-24"></div>
                    <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-24"></div>
                  </form>

                  {/* Display selected store ID for confirmation */}
                  {/* {selectedStore && <p>Selected Store ID: {selectedStore}</p>} */}
                </div>

                <div className="h-2.5 mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  if (loadError) return <div>Error loading maps</div>;
  if (loader)
    return (
      <div>
        <div className="md:flex justify-between gap-10">
          <div className=" md:basis-8/12 mt-10">
            <div className=" border-[2px] rounded-lg px-4 py-3 border-[#E3E4E5] ">
              <div className="flex justify-center items-center h-[90px] bg-gray-300 rounded dark:bg-gray-700"></div>

              <hr className="my-4" />

              <div className="flex justify-center items-center h-[90px] bg-gray-300 rounded dark:bg-gray-700"></div>
            </div>
          </div>

          <div className=" md:basis-4/12 mt-10 mb-5">
            <div className=" border-[2px] rounded-lg px-4 py-3 border-[#E3E4E5]">
              <div className="flex flex-col">
                <div className="flex justify-between my-3">
                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>

                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-24"></div>
                </div>

                <div className="flex items-center justify-between my-3">
                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>

                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
                </div>

                <div className="flex justify-between my-3">
                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>

                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
                </div>
                <div className="relative w-full lg:w-12/12">
                  <div className="flex justify-center items-center h-30 bg-gray-300 rounded dark:bg-gray-700"></div>
                </div>

                <hr className="my-3" />

                <div>
                  <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>

                  <form className="flex flex-col pb-3 gap-4">
                    <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-24"></div>
                    <div className="h-2.5  bg-gray-300 rounded-full dark:bg-gray-400 w-24"></div>
                  </form>

                  {/* Display selected store ID for confirmation */}
                  {/* {selectedStore && <p>Selected Store ID: {selectedStore}</p>} */}
                </div>

                <div className="h-2.5 mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  const handleLoad = (searchBox) => {
    searchBoxRef.current = searchBox;
  };

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
      setAddress(places[0].formatted_address || "");
      setSuggestions([]);
    }
  };

  const handleInputChange = async (e) => {
    const input = e.target.value;
    setAddress(input);

    if (input.length > 2) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: "NG" },
          location: new window.google.maps.LatLng(9.082, 8.6753), // Nigeria's approximate center
          radius: 500000, // 500 km radius
        },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const selectedStoreAddress = selectedStore; // Get the currently selected store address
    setAddress(suggestion.description);
    setSuggestions([]);
    // Pass both the selected store and the suggestion description to getLocation
    getLocation(selectedStoreAddress, suggestion.description);
  };
  return (
    <>
      <Navbar isPaid={paid} />

      <div className="md:flex justify-between gap-10">
        <div className=" md:basis-8/12 mt-10">
          <div className=" border-[2px] rounded-lg px-4 py-3 border-[#E3E4E5] ">
            <div className="border-[2px] rounded-lg px-4 py-3 border-[#E3E4E5] bg-[#F2F8FD]">
              <span className="flex justify-center font-[700] py-5">
                Free shipping, arrives between today, {dayjs().format("MMM DD")}{" "}
                – tomorrow, {dayjs().add(1, "day").format("MMM DD")}
              </span>
            </div>

            <hr className="my-4" />

            <div className="flex flex-col gap-2">
              {data?.map((cart) => (
                <div className="border-[2px] rounded-lg px-4 py-3 border-[#E3E4E5]">
                  <Link
                    to={`/view-product/${cart?.product_id}`}
                    className="flex justify-between"
                  >
                    <div className="flex justify-start gap-2">
                      <span
                        className="border-[2px] rounded-lg px-7 py-6 border-[#E3E4E5] w-3"
                        style={{
                          backgroundImage: `url(${cart?.product_img})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                        }}
                      >
                        {/* <img src={cart?.product_img} className=" w-fit" alt="" /> */}
                      </span>

                      <span className="mt-3">{cart?.product_name}</span>
                    </div>

                    <span className="mt-3">
                      {userLoginData?.data
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "NGN",
                          }).format(
                            Number(cart?.price?.replace(/,/g, "")) *
                              cart?.quantity || 0.0
                          )
                        : "0.0"}
                    </span>
                  </Link>

                  <div className="flex justify-end gap-3">
                    <span
                      className=" cursor-pointer"
                      onClick={() => deleteItem(cart)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.615 20C7.155 20 6.771 19.846 6.463 19.538C6.15433 19.2293 6 18.845 6 18.385V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V18.385C18 18.845 17.846 19.229 17.538 19.537C17.2293 19.8456 16.845 20 16.385 20H7.615ZM9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17Z"
                          fill="#E53945"
                        />
                      </svg>
                    </span>

                    <div>
                      <button
                        className="text-white hover:bg-blue-800 bg-[#027DCB] rounded-l-lg px-2 "
                        onClick={() => reduceCartCount(cart)}
                      >
                        {" "}
                        -{" "}
                      </button>
                      <span className="border border-[#E3E4E5] px-1">
                        {" "}
                        {cart?.quantity ? cart?.quantity : 0}{" "}
                      </span>
                      <button
                        className="text-white hover:bg-blue-800 bg-[#027DCB] rounded-r-lg px-2 "
                        onClick={() => addCartCount(cart)}
                      >
                        {" "}
                        +{" "}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className=" md:basis-4/12 mt-10 mb-5">
          <div className=" border-[2px] rounded-lg px-4 py-3 border-[#E3E4E5]">
            <div className="flex flex-col">
              <div className="flex justify-between my-3">
                <span>Sub Total</span>
                <span className=" font-[200] text-[13px]">
                  ({totalItem} Items)&nbsp;&nbsp;{" "}
                  {userLoginData?.data
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "NGN",
                      }).format(total ? total : 0.0)
                    : "0.0"}{" "}
                </span>
              </div>

              <div className="flex items-center justify-between my-3">
                <div className="flex flex-col">
                  <span>Shipping</span>
                  <span>(Service fee inclusive)</span>
                </div>
                <span className=" font-[200] text-[13px]">
                  {" "}
                  <div className="">
                    {location !== "1" &&
                    selectedPaymentMethod !== "payAtStore" &&
                    selectedDeliveryMethod === "shipping" ? (
                      <p className="text-[12px] text-gray-500">
                        {" "}
                        <b className=" font-bold">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "NGN",
                          }).format(location ? location : 0.0)}{" "}
                        </b>
                      </p>
                    ) : (
                      "0.0"
                    )}

                    {location == "1" ? (
                      <p className="text-[12px] text-gray-500"> Nill</p>
                    ) : (
                      ""
                    )}
                  </div>{" "}
                </span>
              </div>

              {/* <div className="flex justify-between my-3">
                <span>Taxes</span>
                <span className=" font-[200] text-[13px]">
                  Calculated at checkout{" "}
                </span>
              </div> */}

              <div className="flex justify-between my-3">
                <span>Estimated Total</span>
                <span>
                  {" "}
                  {userLoginData?.data
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "NGN",
                      }).format(
                        total
                          ? total +
                              (selectedPaymentMethod !== "payAtStore" &&
                              selectedDeliveryMethod === "shipping"
                                ? location
                                : 0.0)
                          : 0.0
                      )
                    : "0.0"}
                </span>
              </div>
              <div className="relative w-full lg:w-12/12">
                <StandaloneSearchBox
                  onLoad={handleLoad}
                  onPlacesChanged={handlePlacesChanged}
                >
                  {/* <div className=""> */}
                  <div className="relative ">
                    <label className="block mb-2 text-sm font-semibold text-[#414143]">
                      Address
                    </label>

                    <textarea
                      className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      placeholder="Enter Delivery Address"
                      name="address"
                      required
                      value={address ? address : ""}
                      onChange={(e) => handleInputChange(e)}
                      // placeholder="Enter your address"
                      // onChange={() => handleChange}
                    />
                  </div>
                </StandaloneSearchBox>
                {suggestions.length > 0 && (
                  <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion.place_id}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* <div className="relative flex flex-col gap-3 mt-5">
                <StandaloneSearchBox
                  onLoad={handleLoad}
                  onPlacesChanged={handlePlacesChanged}
                >
                 
                    <div className="relative w-full lg:w-12/12">
                      <label className="block mb-2 text-sm font-semibold text-[#414143]">
                        Delivery Address
                      </label>

                      <textarea
                        className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        placeholder="Enter Delivery Address"
                        name="address"
                        required
                        value={address ? address : ""}
                        onChange={(e) => handleInputChange(e)}
                      // placeholder="Enter your address"
                      // onChange={() => handleChange}

                      />

                    </div>

                </StandaloneSearchBox>
                {suggestions.length > 0 && (
                  <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion.place_id}
                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => {
                          setAddress(suggestion.description);
                          setSuggestions([]);
                        }}
                      >
                        {suggestion.description}
                      </li>
                    ))}
                  </ul>
                )}

            </div> */}

              <hr className="my-3" />

              <div>
                <h3 className="text-[#595959] text-[16px] pb-4 pt-1">
                  Select a Store:
                </h3>
                {stores && stores?.length > 0 ? (
                  <form className="flex flex-col pb-3 gap-4">
                    {stores?.map((store) => (
                      <div className="flex gap-3 items-center" key={store.id}>
                        <label className="flex gap-3 items-center">
                          <input
                            type="radio"
                            name="store"
                            value={store?.address} // Use store ID as the value
                            onChange={(e) => handleStoreSelection(e, store)}
                            checked={selectedStore === store?.address} // Ensure it's checked when selected
                          />
                          {store?.name} {/* Show store address */}
                        </label>
                      </div>
                    ))}
                  </form>
                ) : loader ? (
                  <span>
                    <Oval
                      visible={loader}
                      height="20"
                      width="80"
                      color="blue"
                      secondaryColor="#E6F1FC"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </span>
                ) : (
                  <p>No active stores available.</p>
                )}

                {/* Display selected store ID for confirmation */}
                {/* {selectedStore && <p>Selected Store ID: {selectedStore}</p>} */}
              </div>

              {/* Delivery Method */}
              {selectedStore && (
                <div>
                  <h3 className="text-[#595959] text-[16px] pb-4 pt-1">
                    Select Delivery Method:
                  </h3>
                  <div className="flex flex-col pb-3 gap-5">
                    <div className="flex gap-3 items-center">
                      <input
                        type="radio"
                        id="shipping"
                        name="deliveryMethod"
                        value="shipping"
                        onChange={(e) =>
                          setSelectedDeliveryMethod(e.target.value)
                        }
                        checked={selectedDeliveryMethod === "shipping"}
                      />
                      <label htmlFor="shipping">Shipping</label>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="radio"
                        id="pickup"
                        name="deliveryMethod"
                        value="pickup"
                        disabled={
                          selectedStore ===
                          "Jose Maria Court, Olowo-Eko Close, Lagos, Nigeria"
                        } // Disable for this specific store
                        onChange={(e) =>
                          setSelectedDeliveryMethod(e.target.value)
                        }
                        checked={selectedDeliveryMethod === "pickup"}
                      />
                      <label
                        htmlFor="pickup"
                        className={
                          selectedStore ===
                          "Jose Maria Court, Olowo-Eko Close, Lagos, Nigeria"
                            ? "text-gray-400"
                            : ""
                        }
                      >
                        Pickup
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* Address Input for Shipping */}
              {/* {selectedDeliveryMethod === "shipping" && (
                <div className="relative w-full lg:w-12/12">
                  <StandaloneSearchBox
                    onLoad={handleLoad}
                    onPlacesChanged={handlePlacesChanged}
                  >
                   
                    <div className="relative ">
                      <label className="block mb-2 text-sm font-semibold text-[#414143]">
                        Address
                      </label>

                      <textarea
                        className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                        placeholder="Enter Delivery Address"
                        name="address"
                        required
                        value={address ? address : ""}
                        onChange={(e) => handleInputChange(e)}
                        // placeholder="Enter your address"
                        // onChange={() => handleChange}
                      />
                    </div>
                  </StandaloneSearchBox>
                  {suggestions.length > 0 && (
                    <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                      {suggestions.map((suggestion) => (
                        <li
                          key={suggestion.place_id}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              <hr className="my-3" /> */}

              {/* Payment Method */}
              {(selectedDeliveryMethod === "pickup" ||
                selectedDeliveryMethod === "shipping") && (
                <div>
                  <h3 className="text-[#595959] text-[16px] pb-4 pt-1">
                    Payment Method
                  </h3>
                  <div className="flex flex-col pb-3 gap-5">
                    <div className="flex gap-3 items-center">
                      <input
                        type="radio"
                        id="checkout"
                        name="paymentMethod"
                        value="checkout"
                        checked={selectedPaymentMethod === "checkout"}
                        onChange={(e) =>
                          setSelectedPaymentMethod(e.target.value)
                        }
                      />
                      <label htmlFor="checkout">Pay with Card</label>
                    </div>
                    {selectedDeliveryMethod === "pickup" && (
                      <div className="flex gap-3 items-center">
                        <input
                          type="radio"
                          id="payAtStore"
                          name="paymentMethod"
                          value="payAtStore"
                          checked={selectedPaymentMethod === "payAtStore"}
                          onChange={(e) =>
                            setSelectedPaymentMethod(e.target.value)
                          }
                        />
                        <label htmlFor="payAtStore">Pay in Store</label>
                      </div>
                    )}

                    <div className="flex gap-3 items-center">
                      <input
                        type="radio"
                        id="payWithTransfer"
                        name="paymentMethod"
                        value="payWithTransfer"
                        checked={selectedPaymentMethod === "payWithTransfer"}
                        onChange={(e) =>
                          setSelectedPaymentMethod(e.target.value)
                        }
                      />
                      <label htmlFor="payWithTransfer">Pay With Transfer</label>
                    </div>
                    {/* <div
                className="flex gap-3 justify-between mb-3 items-center cursor-pointer"
                onClick={togglePayTransfer}
              >
                <h5>Pay transfer/Pickup at store</h5>
                {isVisible ? <FaChevronUp /> : <FaChevronDown />}
              </div> */}
                    {/* {selectedPaymentMethod === "payWithTransfer" && (
                      <div className="bg-gray-100 p-4 rounded">
                        <p>Here are the payment details:</p>
                        <p>
                          <b>Account Number:</b> 4831280948
                        </p>
                        <p>
                          <b>Bank:</b> Moniepoint MFB
                        </p>
                        <p>
                          <b>Account Name:</b> M-MART PLUS ENTERPRISE - M-MART
                          PLUS ENTERPRISE
                        </p>
                        <p>
                          Message{" "}
                          <a
                            href="https://wa.me/2348148268019?text=Hello%20I%20would%20like%20to%20confirm%20my%20transfer%20"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="text-blue-700  underline"
                          >
                            +2348148268019
                          </a>{" "}
                          for confirmation. Pickup is available at our store
                          during working hours.
                        </p>
                      </div>
                    )} */}
                  </div>
                </div>
              )}
              {selectedPaymentMethod &&
                selectedDeliveryMethod === "shipping" && (
                  <div className="py-4 flex justify-center">
                    {location !== "1" &&
                    selectedPaymentMethod !== "payAtStore" ? (
                      <p className="text-[12px] text-gray-500">
                        {" "}
                        ❗ You will be charged{" "}
                        <b className=" font-bold">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "NGN",
                          }).format(location ? location : 0.0)}{" "}
                        </b>{" "}
                        for delivery.
                      </p>
                    ) : (
                      ""
                    )}

                    {location == "1" ? (
                      <p className="text-[12px] text-gray-500">
                        {" "}
                        ❗We could not estinate your address location to our
                        store.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}

              {selectedStore &&
              selectedDeliveryMethod &&
              selectedPaymentMethod ? (
                <>
                  <button
                    type="button"
                    onClick={handleProceed}
                    disabled={
                      !selectedPaymentMethod ||
                      !selectedStore ||
                      loader ||
                      !selectedDeliveryMethod ||
                      total === 0
                    }
                    className="mt-2 w-full disabled:bg-[#5c5c5c] text-white bg-[#0071BC] hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    <span className="flex justify-center">
                      <span>
                        {loader ? (
                          <Oval
                            visible={loader}
                            height="20"
                            width="80"
                            color="white"
                            secondaryColor="#E6F1FC"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          "Proceed"
                        )}
                      </span>

                      <span></span>
                    </span>
                  </button>
                </>
              ) : (
                <div>
                  <button
                    type="button"
                    disabled={
                      total == 0
                        ? true
                        : false ||
                          !address ||
                          !selectedStore ||
                          !selectedDeliveryMethod ||
                          total === 0
                      // ||
                      // selectedPaymentMethod === "payWithTransfer"
                    }
                    onClick={() => getLocation()}
                    style={{
                      backgroundColor:
                        total == 0 ||
                        !address ||
                        !selectedStore ||
                        !selectedDeliveryMethod ||
                        !selectedPaymentMethod ||
                        selectedPaymentMethod === "payWithTransfer" ||
                        total === 0
                          ? "#5c5c5c"
                          : "#0071BC",
                      borderRadius: "50px",
                    }}
                    className=" mt-2 w-full text-white disabled:bg-[#5c5c5c] hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    <span className="flex justify-center">
                      {loader ? (
                        <Oval
                          visible={loader}
                          height="20"
                          width="80"
                          color="white"
                          secondaryColor="#E6F1FC"
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Continue"
                      )}
                    </span>
                  </button>
                </div>
              )}
              {/* {visible2 ? (
                <div>
                  <h3 className="text-[#595959] text-[16px] pb-4 pt-1">
                    Payment Method
                  </h3>

                  <div className="flex flex-col pb-3 gap-5">
                    <div className="flex gap-3 items-center">
                      <input
                        type="radio"
                        id="checkout"
                        name="paymentMethod"
                        value="checkout"
                        checked={selectedPaymentMethod === "checkout"}
                        onChange={(e) =>
                          setSelectedPaymentMethod(e.target.value)
                        }
                      />
                      <label htmlFor="checkout">Pay with Card</label>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type="radio"
                        id="payAtStore"
                        name="paymentMethod"
                        value="payAtStore"
                        checked={selectedPaymentMethod === "payAtStore"}
                        onChange={(e) =>
                          setSelectedPaymentMethod(e.target.value)
                        }
                      />
                      <label htmlFor="payAtStore">Pay in Store</label>
                    </div>
                  </div>


                  <div
                className="flex gap-3 justify-between mb-3 items-center cursor-pointer"
                onClick={togglePayTransfer}
              >
                <h5>Pay transfer/Pickup at store</h5>
                {isVisible ? <FaChevronUp /> : <FaChevronDown />}
              </div>
              {isVisible && (
                <div className="bg-gray-100 p-4 rounded">
                  <p>Here are the payment details:</p>
                  <p><b>Account Number:</b> 4831280948</p>
                  <p><b>Bank:</b> Moniepoint MFB</p>
                  <p><b>Account Name:</b> M-MART PLUS ENTERPRISE - M-MART PLUS ENTERPRISE</p>
                  <p>Message  <a
                      href="https://wa.me/2348148268019?text=Hello%20I%20would%20like%20to%20confirm%20my%20transfer%20"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-blue-700  underline"
                    >
                     +2348148268019
                    </a>  for confirmation. Pickup is available at our store during working hours.</p>
                </div>
              )}
                  <div className="py-4 flex justify-center">
                    {location !== "1" &&
                    selectedPaymentMethod !== "payAtStore" ? (
                      <p className="text-[12px] text-gray-500">
                        {" "}
                        ❗ You will be charged{" "}
                        <b className=" font-bold">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "NGN",
                          }).format(location ? location : 0.0)}{" "}
                        </b>{" "}
                        for delivery.
                      </p>
                    ) : (
                      ""
                    )}

                    {location == "1" ? (
                      <p className="text-[12px] text-gray-500">
                        {" "}
                        ❗We could not estinate your address location to our
                        store.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={handleProceed}
                    disabled={
                      !selectedPaymentMethod || !selectedStore || loader
                    }
                    className="mt-2 w-full disabled:bg-[#5c5c5c] text-white bg-[#0071BC] hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    <span className="flex justify-center">
                      <span>
                        {loader ? (
                          <Oval
                            visible={loader}
                            height="20"
                            width="80"
                            color="white"
                            secondaryColor="#E6F1FC"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          "Proceed"
                        )}
                      </span>

                      <span></span>
                    </span>
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    disabled={
                      total == 0 ? true : false || !address || !selectedStore
                    }
                    onClick={() => getLocation()}
                    style={{
                      backgroundColor:
                        total == 0 || !address || !selectedStore
                          ? "#5c5c5c"
                          : "#0071BC",
                      borderRadius: "50px",
                    }}
                    className=" mt-2 w-full text-white disabled:bg-[#5c5c5c] hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    <span className="flex justify-center">
                      {loader ? (
                        <Oval
                          visible={loader}
                          height="20"
                          width="80"
                          color="white"
                          secondaryColor="#E6F1FC"
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Continue"
                      )}
                    </span>
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      <section>
        <Modal
          visible={visible2}
          width="400"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setVisible2(false)}
        >
          <div className=" " style={{ height: "100%", overflow: "auto" }}>
            <span className="flex justify-between px-2 py-2">
              <span className="pt-3">How do you want to pay?</span>
              <p
                className="cursor-pointer font-bold"
                onClick={(e) => setVisible2(false)}
              >
                <SvgElement type={icontypesEnum.CANCEL} />
              </p>
            </span>
            <div className=" bg-[#fff]  items-center rounded-lg p-1 px-4">
              <div className="">
                <span className="text-gray-500 text-[12px] flex justify-center pt-4">
                  Do you want to pay for your orders now?
                </span>
                <button
                  type="button"
                  disabled={total == 0 ? true : false}
                  onClick={() => checkout()}
                  style={{ backgroundColor: "#0071BC", borderRadius: "50px" }}
                  className=" mt-2 w-full text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Continue To Checkout
                </button>

                <span className="text-gray-500 text-[12px] flex justify-center pt-4">
                  Do you want to pay from your wallet?
                </span>
                <button
                  type="button"
                  disabled={total == 0 ? true : false}
                  onClick={() => payFromWallet()}
                  style={{ backgroundColor: "#0071BC", borderRadius: "50px" }}
                  className=" mt-2 w-full text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Pay from Wallet
                </button>

                <span className="text-gray-500 text-[12px] flex justify-center pt-8">
                  Do you want to pay for your orders at the store?
                </span>
                <button
                  type="button"
                  disabled={total == 0 ? true : false}
                  onClick={() => PayAtStore()}
                  style={{ backgroundColor: "#0071BC", borderRadius: "50px" }}
                  className=" mt-2 w-full text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Pay at Store
                </button>

                <div className="py-4 mt-10 flex justify-center">
                  {location !== "1" ? (
                    <p className="text-[12px] text-gray-500">
                      {" "}
                      ❗ You will be charged{" "}
                      <b className=" font-bold">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "NGN",
                        }).format(location ? location : 0.0)}{" "}
                      </b>{" "}
                      for delivery.
                    </p>
                  ) : (
                    ""
                  )}

                  {location == "1" ? (
                    <p className="text-[12px] text-gray-500">
                      {" "}
                      ❗We could not estinate your address location to our
                      store.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
