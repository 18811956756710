import React from "react";

// components

import CardMiniStore from "../Cards/CardMiniStore ";
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";

export default function MiniStores() {
  return (
    <>
      <Sidebar title="Dashboard" />
      <div className="relative md:ml-64 bg-white">
        <AdminNavbar title="Dashboard" />
        <div className="flex flex-wrap pt-10">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-1">
            <CardMiniStore />
          </div>
        </div>
      </div>
    </>
  );
}
