import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//@ts-ignore
import Modal from "react-awesome-modal";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { login } from "../../reducer/loginSlice";

function CardUserPurchaseList() {
  const userLoginData = useSelector((state) => state.data.login.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userLists, setUserList] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [selectedOrder, setSelectedOrder] = React.useState(null); // State to track selected order
  // console.log(userLists)
  let [visible, setVisible] = React.useState(false);
  let [orderId, setOrderId] = React.useState([]);
  let [orderStatus, setOrderStatus] = React.useState([]);

  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }
  });

  React.useEffect(() => {
    const query = {
      search: searchText,
    };
    AdminApis.getUserPurchase()
      .then((response) => {
        if (response?.data) {
          const orders = Object.values(response?.data?.data); // Extract values from the object
          setUserList(orders); // Set the state with the orders array
          // console?.log(orders); // Log the orders array
        } else {
          dispatch(login([]));
        }
      })
      .catch(function (error) {});
  }, []);

  const handleShowProducts = (orderId) => {
    // Toggle visibility of the selected order's products
    setSelectedOrder(selectedOrder === orderId ? null : orderId);
  };
  // Grouping the purchases by order_id
  const groupedOrders = userLists?.data?.reduce((acc, purchase) => {
    const { order_id } = purchase;
    if (!acc[order_id]) {
      acc[order_id] = [];
    }
    acc[order_id].push(purchase);
    return acc;
  }, {});

  // console.log(groupedOrders)
  const paginator = React.useCallback(
    (value) => {
      let value2 = "";
      if (value !== null) {
        value2 = value;
      } else {
        value2 = "";
      }
      const query = {
        search: searchText,
      };

      AdminApis.getUserPurchase(value2, query)
        .then((response) => {
          if (response?.data) {
            setUserList(response?.data?.data);
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
    },
    [userLists, searchText]
  );

  const updatePurchase = React.useCallback(
    (e) => {
      e.preventDefault();
      let data = {
        status: orderStatus,
        order_id: orderId,
      };

      AdminApis.updatePurchases(data)
        .then((response) => {
          if (response?.data) {
            setVisible(false);
            toast.success(response?.data?.message);

            const query = {
              search: searchText,
            };
            AdminApis.getUserPurchase("", query).then((response) => {
              if (response?.data) {
                setUserList(response?.data?.data);
                // console?.log(response?.data?.data)
              } else {
                dispatch(login([]));
              }
            });
          } else {
            toast.error(response?.response?.data?.message);
          }
        })
        .catch(function (error) {
          toast.error(error.response.data.message);
        });
    },
    [orderId, orderStatus, visible, userLists]
  );

  return (
    <>
      <div className="bg-white">
        <div className="py-4 lg:px-10 px-6 ">
          <h1 className="text-[30px] font-semibold">Orders</h1>
          {/* <div className='flex justify-end'>
            <div className=" mr-3 flex justify-end">
              <div className="relative flex w-full flex-wrap items-stretch">
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                </div>
                <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
              </div>

              <div className='mt-0.5 ml-2'><button type='button' onClick={(e) => paginator('')} className={"font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"}>Search</button> </div>
       
            </div>

          </div> */}

          {userLists?.map((datas, index) => {
                  const totalPrice = datas?.products?.reduce(
                (sum, product) =>
                  sum + parseFloat(product?.price || 0) * parseFloat(product?.quantity || 1),
                0
              );
              return (
            <div key={index} className=" shadow-lg  my-3 border rounded-lg">
              <div
                onClick={() => handleShowProducts(datas?.order_id)}
                className="cursor-pointer px-3 py-2"
              >
                <div className="flex justify-between items-center">
                <div className="flex flex-col">
                        <p className="text-[14px] font-[500]">
                          Order id: {datas?.order_id}
                        </p>
                        <p className="text-[14px] font-bold">
                          Name: {datas?.customer?.last_name}{" "}
                          {datas?.customer?.first_name}
                        </p>
                        <p className="text-[14px] font-[500]">
                          Phone Number: {datas?.customer?.phone_number}
                        </p>
                        <p className="text-[14px]">
                          Email: {datas?.customer_email}
                        </p>
                      </div>
                  <div>
                    <div className="flex items-center gap-2">
                      {/* Order Status */}
                      {datas?.order_status && (
                        <p>
                          <b
                            style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              backgroundColor:
                                datas?.order_status === "pending"
                                  ? "#FFDDBD"
                                  : datas?.order_status === "paid"
                                  ? "#C9F0D0"
                                  : datas?.order_status === "delivered"
                                  ? "#FFDDBD"
                                  : datas?.order_status === "rejected"
                                  ? "#F5BFBF"
                                  : datas?.order_status === "InStore"
                                  ? "#E6BDFF"
                                  : datas?.order_status === "Breakdown"
                                  ? "#FEF1E8"
                                  : "#CAD8FA",
                              color:
                                datas?.order_status === "pending"
                                  ? "#FF922B"
                                  : datas?.order_status === "paid"
                                  ? "#51CF66"
                                  : datas?.order_status === "delivered"
                                  ? "#FF922B"
                                  : datas?.order_status === "rejected"
                                  ? "#E03130"
                                  : datas?.order_status === "InStore"
                                  ? "#B24BF1"
                                  : datas?.order_status === "Breakdown"
                                  ? "#F97316"
                                  : "#4979ED",
                              borderRadius: "10px",
                              padding: "2px 10px",
                            }}
                          >
                            {datas?.order_status}
                          </b>
                        </p>
                      )}
                      {/* See All Products Button */}
                      {/* <button
                onClick={() => handleShowProducts(datas?.order_id)}
                style={{
                
                  color: '#4979ED',
                }}
              >
                See Products
              </button> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Show Products if selected */}
              {selectedOrder === datas?.order_id && (
                <div className=" md:mx-7 mt-3">
                         <div className="flex justify-between">
                      <h3 className="px-3">Products:</h3>
                      <div className="">
                          <b>Date: </b>
                          {datas?.date_of_purchase}
                        </div>
</div>
                  <table className=" text-sm overflow-auto text-gray-500 ">
                    <div className="w-full overflow-auto">
                      <thead className="text-xs  text-gray-700 bg-gray-50 ">
                        <tr className="">
                          <th scope="col" className="md:px-4 px-3 py-3">
                            S/N
                          </th>
                          {/* <th scope="col" className="px-6 py-3">
                      Order Id
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Customer
                    </th> */}
                          <th scope="col" className="md:px-4 py-3">
                            Product
                          </th>
                          <th scope="col" className="md:px-4 py-3">
                            Quantity
                          </th>

                          <th scope="col" className="md:px-4 py-3">
                            Price
                          </th>
                          {/* <th scope="col" className="px-6 py-3">
                      Status
                    </th> */}
                        </tr>
                      </thead>
                      <tbody className="">
                        {datas?.products?.map((product, productIndex) => (
                          <tr key={productIndex} className="py-1">
                            <td className="md:px-4 px-3 text-center  py-4">
                              {productIndex + 1}
                            </td>
                            <td className="md:px-4 text-center py-4">
                              {product?.product_name}
                            </td>
                            <td className="md:px-4 text-center py-4">
                              {product?.quantity}
                            </td>
                            <td className="md:px-4 text-center py-4">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "NGN",
                              }).format(product?.price ? product?.price : 0.0)}
                            </td>
                            {/* <b>{product?.product_name}</b> - {product?.quantity} x{' '}
                        {product?.price} */}
                          </tr>
                        ))}
                      </tbody>
                    </div>
                  </table>
                  <div className="md:flex justify-between px-3 mt-6 mb-2">
                        <div className="">
                          <b>Address: </b>
                          {datas?.customer?.address}
                        </div>

                        <div className="md:mt-0 mt-2">
                          <b>Total Price: </b>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "NGN",
                          }).format(totalPrice)}
                        </div>
                      </div>
                </div>
              )}
            </div>
)})}

          {/* <div className="w-auto overflow-auto shadow-lg sm:rounded-lg mt-6">
            <table className=" text-sm overflow-auto text-gray-500 ">
              <div className='w-[80vw] md:w-full overflow-auto'>

                <thead className="text-xs text-gray-700 bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      S/N
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Order Id
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Customer
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Product
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Quantity
                    </th>

                    <th scope="col" className="px-6 py-3">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                  </tr>
                </thead>

                <tbody className=''>
                  {
                    userLists?.data?.filter((data) => data?.role !== 'admin')?.map(
                      (datas, index) => (
                        <tr className="bg-white  ">

                          <td className="px-6 py-4">
                            {index + 1}
                          </td>

                          <td className="px-6 py-4">
                            {datas?.order_id}
                          </td>
                          <td className="px-6 py-4">
                            {datas?.customer_email}
                          </td>
                          <td className="px-6 py-4">
                            {datas?.product_name}
                          </td>
                          <td className="px-6 py-4">
                            {datas?.quantity}
                          </td>
                          <td className="px-6 py-4">
                            {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((datas?.price) ? (datas?.price) : 0.0))}

                          </td>
                          <td className="px-6 py-4">
                          {datas?.order_status == 'pending' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                            {datas?.order_status == 'paid' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                            {datas?.order_status == 'delievered' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                            {datas?.order_status == 'rejected' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#F5BFBF', color: '#E03130', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                            {datas?.order_status == 'InStore' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                            {datas?.order_status == 'Breakdown' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FEF1E8', color: '#F97316', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                            {datas?.order_status == 'CheckedOut' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}

                           
                          </td>




                        </tr>
                      )
                    )
                  }

                </tbody>
              </div>
            </table>

          </div> */}
          <div className="m-4 flex justify-end">
            {userLists?.links
              ?.filter((item, idx) => idx < 1000)
              .map((datas, index) => (
                <button
                  onClick={() =>
                    paginator(
                      datas?.label == "Next &raquo;"
                        ? datas?.url.charAt(datas?.url.length - 1)
                        : datas?.label === "&laquo; Previous"
                        ? datas?.url.charAt(datas?.url.length - 1)
                        : datas?.label
                    )
                  }
                  disabled={datas?.active}
                  className={
                    "mx-1 py-1 px-2 " +
                    (datas?.active == false
                      ? "bg-gray-300 text-black "
                      : "bg-[#0071BC] text-white")
                  }
                >
                  {datas?.label == "&laquo; Previous"
                    ? "< Previous"
                    : datas?.label === "Next &raquo;"
                    ? "Next  >"
                    : datas?.label}
                </button>
              ))}
          </div>
        </div>
      </div>

      <section>
        <Modal
          visible={visible}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setVisible(false)}
        >
          <div className=" " style={{ height: "100%", overflow: "auto" }}>
            <span className="flex justify-end pr-2 pt-2">
              <p
                className="cursor-pointer font-bold"
                onClick={(e) => setVisible(false)}
              >
                <SvgElement type={icontypesEnum.CANCEL} />
              </p>
            </span>
            <div className=" bg-[#fff]  items-center rounded-lg p-1 px-4">
              <div className="">
                <span>Update Purchase</span>

                <form onSubmit={updatePurchase}>
                  <div className="grid md:grid-cols-2 gap-2">
                    <div>
                      <label
                        for="first_name"
                        class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600"
                      >
                        Order Id
                      </label>
                      <input
                        required
                        type="text"
                        onChange={(e) => setOrderId(e.target.value)}
                        id="first_name"
                        class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                      />
                    </div>

                    <div>
                      <label
                        for="first_name"
                        class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600"
                      >
                        Category
                      </label>
                      <select
                        id="gender"
                        required
                        onChange={(e) => setOrderStatus(e.target.value)}
                        name="programs_type"
                        class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer"
                      >
                        <option selected value="">
                          Select Status
                        </option>
                        <option value="paid">Paid</option>
                        <option value="delievered">Delievered</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    </div>
                  </div>

                  <span className="flex justify-center pt-8">
                    <button
                      type="submit"
                      style={{
                        backgroundColor: "#0071BC",
                        borderRadius: "50px",
                      }}
                      className=" text-white hover:bg-[#0071BC] focus:ring-4 focus:outline-none focus:ring-[#0071BC] font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Update
                    </button>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default CardUserPurchaseList;
